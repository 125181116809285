import { Injectable } from "@angular/core";
import { IDropdown } from "../interface/master.interface";
import { ILogin } from "../../interfaces/login.interfac";


@Injectable({
  providedIn: 'root'
})
export class DataService {
  showErrorInUI = false;
  observationType: IDropdown[] = [{
    label: 'None',
    value: 'NONE'
  },{
    label: 'Area of improvement',
    value: 'NEED_IMPROVEMENTS'
  },{
    label: 'Critical',
    value: 'CRITICAL'
  },{
    label: 'Commendable',
    value: 'COMMANDABLE'
  }]


  constructor() {}

  clearSessionStorage() :void{
    sessionStorage.clear();
  }
  clearLocalStorage() :void{
    localStorage.clear();
  }

  setSessionStorageItem(field: string, value: any) :void{
    if (typeof value !== 'string') {
      value = value.toString()
    }
    sessionStorage.setItem(field, value)
  }
  setLocalStorageItem(field: string, value: any) :void{
    if (typeof value !== 'string') {
      value = value.toString()
    }
    localStorage.setItem(field, value)
  }

  getSessionStorageItem(field: string): string | object | any[] {
    const fieldData = sessionStorage.getItem(field) || '';
    try {
      const parsedData = JSON.parse(fieldData);
      if (Array.isArray(parsedData)) {
        return parsedData;
      } else if (typeof parsedData === 'object') {
        return parsedData;
      } else if (typeof parsedData === 'string') {
        return parsedData;
      }
      return fieldData;
    } catch (error) {
      return fieldData;
    }
  }

  getLocalStorageItem(field: string): any{
    const fieldData = localStorage.getItem(field) || '';
    try {
      const parsedData = JSON.parse(fieldData);
      if (Array.isArray(parsedData)) {
        return parsedData;
      } else if (typeof parsedData === 'object') {
        return parsedData;
      } else if (typeof parsedData === 'string') {
        return parsedData;
      }
      return fieldData;
    } catch (error) {
      return fieldData;
    }
  }
  public saveLoginResponse(loginResponse: ILogin): void {
    localStorage.setItem('access_token', loginResponse.access_token);
    localStorage.setItem('user_id', loginResponse.user_id);
    localStorage.setItem('user_name', loginResponse.user_name);
    localStorage.setItem('user_type', loginResponse.user_type);
    localStorage.setItem('tenant_name', loginResponse.tenant_name);
  }

}
